/* You can add global styles to this file, and also import other style files */
// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "bootstrap/scss/functions";

// 2. Include any default variable overrides here
@import "assets/scss/custom-variables";
@import "bootstrap-icons/font/bootstrap-icons.css";

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";

// 5. Include remainder of required parts
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";

// 6. Optionally include any other parts as needed (components, helpers, grids, containers, etc.)
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/helpers";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/close";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "bootstrap/scss/utilities/api";

// @import './node_modules/ngx-bootstrap/datepicker/bs-datepicker';
@import "ngx-toastr/toastr-bs5-alert";

// 8. Components custom or override styles
// @import 'assets/scss/component/buttons';
// @import 'assets/scss/component/form';
// @import 'assets/scss/component/tooltip';
// @import 'assets/scss/component/profile-thumbnail';
// @import 'assets/scss/component/tables';
// // @import 'assets/scss/component/daterangepicker';
// @import 'assets/scss/component/tree';
// @import 'assets/scss/component/dropdown';

// // 10. Add additional custom code here
@import "assets/scss/base";
// @import 'assets/scss/colors';
// @import 'assets/scss/typography';
// @import 'assets/scss/common';
// @import 'assets/scss/layout';
@import "assets/scss/full-calendar";
@import "ngx-bootstrap/datepicker/bs-datepicker.css";

.sign-up-form {
  .has-feedback {
    .form-control-feedback {
      span {
        margin-left: 10px;
        color: #dc3545 !important;
      }
    }
  }
}

.btn-secondary {
  --bs-btn-bg: #08a1c4;
  border-radius: 2px;
  border: 0;
}

.bg-primary {
  background-color: #21b4a7 !important;
}

.btn-primary {
  --bs-btn-bg: #1b8c83;
  border-radius: 2px;
  background-color: #21b4a7;
  border: 0;

  &:hover {
    background-color: #1b8c83;
    border-color: #1b8c83;
    border-radius: 2px;
  }

  &:focus {
    background-color: #1b8c83 !important;
    border-color: #1b8c83 !important;
    border-radius: 2px;
  }
}

.btn {
  &:hover {
    background-color: #1b8c83;
    border-color: #1b8c83;
    border-radius: 2px;
  }

  &:disabled {
    background-color: #1b8c83;
    border-color: #1b8c83;
    opacity: 1;
  }
}

.hrdivider {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #aeaeae;
  line-height: 0.1em;
  margin: 14px 0 14;
  font-size: 14px;
  color: #484848;

  span {
    background: #f6fffc;
    padding: 0 20px;
    color: #484848;
  }
}

.btn-outline-dark {
  &:hover {
    background-color: #1b8c83;
    border-color: #1b8c83;
    border-radius: 2px;
  }

  &:focus {
    background-color: #1b8c83 !important;
    border-color: #1b8c83 !important;
    border-radius: 2px;
  }
}

a {
  color: #1b8c83;
  text-decoration: none;
  cursor: pointer;
}

// Loader
.ngx-spinner-overlay {
  z-index: 1040 !important;
  background-color: rgba(var(--bs-white-rgb), 0.5) !important;
  backdrop-filter: blur(2px) grayscale(0.25);
  .la-ball-scale-multiple.la-2x {
    border-radius: 50rem;
    width: 64px;
    height: 64px;
    background-repeat: no-repeat;
    background-image: url("./assets/images/loader.gif");
    background-position: center center;
    background-size: 64px 64px;
    box-shadow: $box-shadow;
    div {
      display: none;
    }
  }
}

.dropdown-menu {
  border-radius: 4px;
  padding: 10px 0;
  animation-name: dropdown-animate;
  animation-duration: 0.2s;
  animation-fill-mode: both;
  border: 0;
  box-shadow: 0 5px 30px 0 rgba(82, 63, 105, 0.2);

  .dropdown-divider {
    color: #a5c5fe;
    margin: 0;
  }
}

.dropdown-menu .dropdown-header,
.dropdown-menu .dropdown-footer {
  text-align: center;
  font-size: 15px;
  padding: 10px 25px;
}

.toast-title {
  display: none;
}

.btn-link {
  color: #21b4a7;
}

.btn-link {
  :hover {
    color: #1b8c83;
  }
}

.ngb-dp-weekdays {
  .ngb-dp-weekday {
    color: #21b4a7 !important;
  }
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active,
.btn:focus,
.btn:hover {
  background-color: #1b8c83;
  border-color: #1b8c83;
  border-radius: 2px;
}

.btn-outline-secondary {
  border-radius: inherit;
  background-color: #21b4a7;
  border-color: #21b4a7;
  color: #f6fffc;
}

.form-select {
  border: none;
  border-radius: 0;
  box-shadow: 0 2px 15px rgba(33, 100, 167, 0.08);
  border-bottom: 1px solid #b5b5b5;
  padding: 11px;
}
.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.theme-blue {
  .bs-datepicker-head {
    background-color: #21b4a7;
  }

  .bs-datepicker-body {
    table {
      td {
        &.week {
          span {
            color: #08a1c4;
          }
        }
      }
    }
  }
}

.max-width {
  max-width: 1340px;
}

.w-15 {
  width: 15% !important;
}

.btn-light {
  background-color: #f3f4f5;
  border: 0;
  border-radius: 2px;

  &:hover {
    color: #fff;
    border-radius: 2px;
  }

  &:focus {
    background-color: #1b8c83 !important;
    border-color: #1b8c83 !important;
    border-radius: 2px;
    color: #fff !important;
  }
}

.form-control {
  &.is-valid {
    background-image: none;
  }
}

.form-select {
  &.is-valid {
    background-image: none;
  }
}
