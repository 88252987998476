.fc-bg-warning {
  background-color: #efb540;
  border: none;
  &:hover {
    background-color: #ecaa25;
  }
}

.fc-header-toolbar {
  .fc-toolbar-chunk {
    .btn-group {
      button {
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.875rem;
      }
    }
  }
}
