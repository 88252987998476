// Variables
$default-color: var(--default-color);
$background-color: var(--background-color);
$default-font: var(--default-font);
$primary-color: var(--primary-color);
$heading-font: var(--heading-font);
$nav-hover-color: var(--nav-hover-color);

// Body Styles
body {
  color: $default-color;
  background-color: $background-color;
  font-family: $default-font;
}

a {
  color: var($primary-color);
  text-decoration: none;
  transition: 0.3s;
}

a:hover {
  color: rgba($nav-hover-color, 0.7);
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $primary-color;
  font-family: $heading-font;
}

// Section Styles
section {
  color: $default-color;
  background-color: $background-color;
  padding: 80px 0;
  overflow: clip;
}

/*--------------------------------------------------------------
# Section Title
--------------------------------------------------------------*/
.section-title {
  text-align: center;
  padding-bottom: 60px;

  h2 {
    font-size: 32px;
    font-weight: 600;
    position: relative;
    color: #212529;

    .primary {
      color: $primary-color;
    }

    &:before,
    &:after {
      content: "";
      width: 50px;
      height: 2px;
      background: $primary-color;
      display: inline-block;
    }

    &:before {
      margin: 0 15px 10px 0;
    }

    &:after {
      margin: 0 0 10px 15px;
    }
  }

  p {
    margin-bottom: 0;
    color: #6d737a;
  }
}

section {
  scroll-margin-top: 90px;
}

// Media Query
@media (max-width: 1199px) {
  section {
    scroll-margin-top: 66px;
  }
}
