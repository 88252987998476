// Fonts
:root {
  --default-font: "Public Sans", "Open Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --heading-font: "Public Sans", sans-serif;
  --nav-font: "Lato", sans-serif;
}

// Global Colors
:root {
  --background-color: #ffffff;
  --background-color-rgb: 255, 255, 255;
  --default-color: #212529;
  --default-color-rgb: 33, 37, 41;
  --primary-color: #21b4a7;
  --primary-color-rgb: 33, 180, 167;
  --secondary-color: #08a1c4;
  --secondary-color-rgb: 8, 161, 196;
  --contrast-color: #ffffff;
  --contrast-color-rgb: 255, 255, 255;
}

// Nav Menu Colors
:root {
  --nav-color: #3a3939;
  --nav-hover-color: #21b4a7;
  --nav-dropdown-color: #3a3939;
  --nav-dropdown-hover-color: #08a1c4;
  --nav-dropdown-background-color: #ffffff;
  --nav-mobile-background-color: #ffffff;
}

// Smooth scroll
:root {
  scroll-behavior: smooth;
}
